import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import TimesTable from './pages/TimesTable';

const Navbar = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/home">메인 페이지</Link>
        </li>
        <li>
          <Link to="/timesTable">구구단</Link>
        </li>
      </ul>
    </nav>
  );
};

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/timesTable" element={<TimesTable />} />
      </Routes>
    </>
  );
};

export default App;
