import React from 'react';

class Home extends React.Component {
  render() {
    return(
      <h1>첫 화면! 이 웹 사이트를 멋진 리액트 사이트로 만들어 보아요!</h1>
    )
  }
}

export default Home