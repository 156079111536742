import React from 'react';

class TimesTable extends React.Component {
  render() {
    return(
      <h1>구구단 페이지</h1>
    )
  }
}

export default TimesTable